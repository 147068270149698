import { createContext, useContext, ReactNode } from 'react';
import { Toaster, createToaster } from '@ark-ui/react';
import Toast, {ToastProps} from '@/components/ui/Toast';

const toaster = createToaster({
  placement: 'bottom-end',
  duration: 3000,
  overlap: true,
  gap: 24,
});

interface ToastContextType {
  createToast: (props: ToastProps) => void;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export const ToastProvider = ({ children }: {children: ReactNode}) => {
  const createToast = (props: ToastProps) => {
    toaster.create(props);
  };
  return (
    <ToastContext.Provider value={{ createToast }}>
      {children}
      <Toaster toaster={toaster} className=''>
        {(toast) => <Toast {...toast} />}
      </Toaster>
    </ToastContext.Provider>
  );
};

export const useToast = (): ToastContextType => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};
