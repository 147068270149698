import ConfirmationDialog from "@/components/ConfirmationDialog";
import {useDeleteDeck} from "@/api/decks/useDeleteDeck";
import {queryClient} from "@/api/client";
import { useToast } from "@/components/ui/ToastContext";

interface DeleteDeckDialogProps {
    deckId: string,
    open: boolean,
    onClose: () => void
}

export default function DeleteDeckDialog({deckId, open, onClose}: DeleteDeckDialogProps) {
    const {createToast} = useToast();
    const {mutateAsync, isPending} = useDeleteDeck({
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ['decks']})
            onClose()
            createToast({
                title: 'Tema eliminado', 
                description: 'El tema ha sido eliminado correctamente', 
                type: 'success'
            })
        },
        onError: () => {
            onClose()
            createToast({
                title: 'Error', 
                description: 'Ha ocurrido un error al intentar eliminar el tema', 
                type: 'error'}
            )
        }
    });

    if (open) return (
        <ConfirmationDialog
            open={true}
            onClose={onClose}
            title={'Eliminar Tema'}
            description={'¿Estás seguro de que deseas eliminar este tema?'}
            confirmText='Eliminar'
            onConfirm={async () => await mutateAsync({deckId: deckId})}
            isPending={isPending}
        />
    )
}