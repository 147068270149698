import React from 'react';
import {BiArrowBack} from "react-icons/bi";
import {useNavigate} from "react-router-dom";
interface props{
    title ?: string,
    isDetailed ?: boolean
    children : React.ReactNode,
    status ?: "pending" | "success" | "error"
}

function PageLayout({title, children, isDetailed, status} : props) {
    const navigate = useNavigate();

    if(status === "pending") return "pending"
    if(status === "error") return "error"

    return (
        <div>
        <div className="flex gap-3 items-center">
            {
                isDetailed &&
                <div onClick={() => navigate(-1)}
                     className="flex-shrink-0 w-[40px] h-[40px] bg-gray-100 flex justify-center items-center rounded-full text-gray-500 cursor-pointer hover:bg-gray-200">
                    <BiArrowBack size={20}></BiArrowBack>
                </div>
            }
            <h1 className="m-0 text-gray-700 text-2xl font-bold truncate ">{title}</h1>
        </div>

            {children}
        </div>
    );
}

export default PageLayout;