import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import dingSound from '@/assets/positive.mp3'
import { useModifyStats } from '@/api/statistics/useModifyStats';
import { queryClient } from '@/api/client';
import { set } from 'cypress/types/lodash';

type PomodoroStatus = 'focus' | 'rest';

export interface StartPomodoroParams {
  status: PomodoroStatus;
  times: {
    focus: number;
    rest: number;
  };
}

export interface PomodoroContextType {
  status: PomodoroStatus;
  timeLeft: number;
  isRunning: boolean;
  hasStarted: boolean;
  startPomodoro: (params: StartPomodoroParams) => void;
  reset: () => void;
  skip: () => void;
  toggleRunning: () => void;
  values: StartPomodoroParams;
}

const PomodoroContext = createContext<PomodoroContextType | undefined>(undefined);

export const usePomodoro = (): PomodoroContextType => {
  const context = useContext(PomodoroContext);
  if (!context) {
    throw new Error('usePomodoro must be used within a PomodoroProvider');
  }
  return context;
};

interface PomodoroProviderProps {
  children: ReactNode;
}

const PERSISTENCE_KEY = 'pomodoroState';

export const PomodoroProvider: React.FC<PomodoroProviderProps> = ({ children }) => {
  const [status, setStatus] = useState<PomodoroStatus>('focus');
  const [timeLeft, setTimeLeft] = useState<number>(25 * 60);
  const [focusTime, setFocusTime] = useState<number>(25 * 60);
  const [restTime, setRestTime] = useState<number>(5 * 60);
  const [isRunning, setIsRunning] = useState<boolean>(false);
  const [hasStarted, setHasStarted] = useState<boolean>(false);
  const [audioBuffer, setAudioBuffer] = useState<AudioBuffer | null>(null);
  const [dateLastStart, setDateLastStart] = useState<Date>(new Date());
  const [timeLastStart, setTimeLastStart] = useState<number>(25 * 60);
  const audioContext = new AudioContext();

  const { mutateAsync, status: statusModifyStats } = useModifyStats({
    onSuccess: () => null,
  });

  // Load state from localStorage
  useEffect(() => {
    const loadState = async () => {
      const savedState = localStorage.getItem(PERSISTENCE_KEY);
      if (savedState) {
        const {
          status,
          timeLeft,
          focusTime,
          restTime,
          isRunning,
          hasStarted,
          dateLastStart,
          timeLastStart,
        } = JSON.parse(savedState);
        setStatus(status);
        setTimeLeft(timeLeft);
        setFocusTime(focusTime);
        setRestTime(restTime);
        setIsRunning(isRunning);
        setHasStarted(hasStarted);
        setDateLastStart(dateLastStart);
        setTimeLastStart(timeLastStart);
      }
      const response = await fetch(dingSound);
      const arrayBuffer = await response.arrayBuffer();
      const decodedBuffer = await audioContext.decodeAudioData(arrayBuffer);
      setAudioBuffer(decodedBuffer);
    };
    loadState();
  }, []);

  // Save state to localStorage on change
  useEffect(() => {
    const stateToSave = {
      status,
      timeLeft,
      focusTime,
      restTime,
      isRunning,
      hasStarted,
      dateLastStart,
      timeLastStart,
    };
    localStorage.setItem(PERSISTENCE_KEY, JSON.stringify(stateToSave));
  }, [status, timeLeft, focusTime, restTime, isRunning, hasStarted, dateLastStart, timeLastStart]);

  useEffect(() => {
    let timer: ReturnType<typeof setInterval> | undefined;
    if (isRunning) {           
      timer = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timer);
            playSound();
            skip(true);
            return status === 'focus' ? restTime : focusTime;
          }
          return (timeLastStart - (new Date().getTime()-new Date(dateLastStart).getTime())/1000);
        });
      }, 1000);
    }
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [isRunning, status, focusTime, restTime]);


  const playSound = async () => {
    if (audioBuffer) {
      const source = audioContext.createBufferSource(); 
      source.buffer = audioBuffer; 
      source.connect(audioContext.destination); 
      source.start(0); 
    }
  };

  const startPomodoro = ({ status, times }: StartPomodoroParams) => {
    setStatus(status);
    setFocusTime(times.focus * 60);
    setRestTime(times.rest * 60);
    setTimeLeft(status === 'focus' ? times.focus * 60 : times.rest * 60);
    setIsRunning(true);
    setHasStarted(true);
    setDateLastStart(new Date());
    setTimeLastStart(status === 'focus' ? times.focus * 60 : times.rest * 60); 
  };

  const toggleRunning = () => {
    if (isRunning){
      let lastTime= timeLastStart
      setTimeLastStart(lastTime - (new Date().getTime()-new Date(dateLastStart).getTime())/1000);
      setTimeLeft(lastTime - (new Date().getTime()-new Date(dateLastStart).getTime())/1000);
    }
    else{
      setDateLastStart(new Date());
    }
    setIsRunning((value) => !value);  
  } 

  const reset = () => {
    setIsRunning(false);
    setHasStarted(false);
    setTimeLeft(status === 'focus' ? focusTime : restTime);
    setDateLastStart(new Date());
    setTimeLastStart(status === 'focus' ? focusTime : restTime);
  };

  const skip = (finished: boolean = false) => {
    if(status === 'focus'){
      if(finished){
        mutateAsync({ minEstudioHoy: (focusTime)/60});
      } 
      else{
        if (isRunning){
          mutateAsync({ minEstudioHoy: (focusTime-(timeLastStart - (new Date().getTime()-dateLastStart.getTime())/1000))/60});
        } else{
          mutateAsync({ minEstudioHoy: (focusTime-timeLeft)/60});
        }
      } 
    }
    const nextStatus = status === 'focus' ? 'rest' : 'focus';
    setStatus(nextStatus);
    setTimeLeft(nextStatus === 'focus' ? focusTime : restTime);
    setDateLastStart(new Date());
    setTimeLastStart(nextStatus === 'focus' ? focusTime : restTime);
  };

  return (
      <PomodoroContext.Provider
          value={{
            status,
            timeLeft,
            isRunning,
            hasStarted,
            startPomodoro,
            reset,
            skip,
            toggleRunning,
            values: {
              status: status,
              times:{
                rest: restTime/60,
                focus:focusTime/60
              }
            }
      }}
      >
        {children}
      </PomodoroContext.Provider>
  );
};
