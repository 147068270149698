import Icon from '@/components/icons/Icon'
import '@/styles/Mazos.css'

interface FloatingPlayButtonProps {
	text?: string
	onClickPlay: () => void
}

export const FloatingPlayButton = ({ text, onClickPlay }: FloatingPlayButtonProps) => {
	return (
		<div className="max-w-[350px] w-full fixed bottom-0 m-5 mx-auto left-0 right-0 box-border">
		<div className="w-full p-2 pl-7 gap-3  bg-brand_secondary-700 box-border
		font-semibold text-white rounded-full cursor-pointer
		 flex items-center justify-between" onClick={onClickPlay}>
			<h4 className="font-semibold text-sm md:text-base break-all ">{text}</h4>

			<Icon iconName='play' size='20' className='hover:!bg-background-color hover:!bg-gray-200 hover:!text-gray-600 min-h-[36px] min-w-[36px] md:min-h-[45px] md:min-w-[45px] bg-gray-100 text-gray-600 rounded-full'  />
		</div>
		</div>
	)
}
