import {Link} from "react-router-dom";
import PricingPlans from "./components/PricingPlans";
import FetchLayout from "@/components/layout/FetchLayout";
import PageLayout from "@/components/layout/PageLayout";
import SEO from "@/components/SEO";
import {useSubscription} from "@/api/user/useSubscription"

function PricingPage() {
    const {data: subscription, status} = useSubscription()

    return (<>
        <SEO title="Planes de pago - Bilern" description="Cambia tu plan de estudio de Bilern y mejora tu rendimiento académico con nuestras opciones más PRO. Elige el plan que mejor se adapte a tus necesidades y alcanza el 100% de tu potencial" />
        <FetchLayout statusArray={[status]}>
            <div className="gap-3 w-full max-w-[1200px] mx-auto py-4" >
                <PageLayout title="Planes de pago" isDetailed={true}>
                    <div className="py-6">
                        <h1 className="text-3xl font-bold text-gray-800">Es momento de dar el <span>100%</span></h1>
                        <h3 className="text-gray-600 mt-0">Da un impulso a tu rendimiento académico con los planes PRO de bilern.</h3>
                        <PricingPlans subscription={subscription!} />
                        <div className="mt-5 font-semibold text-gray-700">
                            Consulta las condiciones de los planes en <Link className="text-brand_secondary-500 hover:text-brand_secondary-700" to="/terminos">Terminos y condiciones</Link>
                        </div>
                    </div>
                </PageLayout>
            </div>
        </FetchLayout>
    </>);
}

export default PricingPage;