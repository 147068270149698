import React, { useEffect, useState } from 'react'
import { GoBackArrow} from '@/components/ui/GoBackArrow'
import { Exam } from '@/types/types'
import LoadingTest from '@/components/ui/LoadingTest'
import './Exam.css'
import { Card, FlashCard, OcultarCard, TestCard, FlashCardInit, OcultarCardInit, TestCardInit } from '@/types/db/card'
import FlashCardStudy from './FlashCardStudy'
import OcultarCardStudy from './OcultarCardStudy'
import TestCardStudy from './TestCardStudy'
import { EditorsProvider } from '@/context/EditorsContext'
import Popup from '@/components/ui/Popup'
import ConfirmDeleteCard from './ConfirmDeleteCard'
import CardEditor from "@/pages/App/pages/Library/pages/DetailedCard/pages/DetailedCardCreate/components/CardEditor";
import Icon from "@/components/icons/Icon";
import { useNavigate } from 'react-router-dom'
import Button from '@/components/ui/Button'
import {BiTimer} from "react-icons/bi";

interface StudyProps {
  cartas: Card[] | null
  modo: number | null
  examen?: Exam | null
  time: number | null
  onClickReturn: () => void
}

export default function Study({ cartas, modo, examen, time, onClickReturn }: StudyProps){

  type CardInit = Card | FlashCardInit | OcultarCardInit | TestCardInit

  const [loading, setLoading] = useState(true)
  const [flashcardsEstudiar, setFlashcardEstudiar] = useState<Card[]>([])
  const [flashcardIndex, setFlashcardIndex] = useState(0)
  const [mostrarRespuesta, setMostrarRespuesta] = useState(false)
  const [finPreguntas, setFinPreguntas] = useState(false)
  const [flashcardsEstudiarSinOrdenar, setFlashcardEstudiarSinOrdenar] = useState<Card[]>([])
  const [tiempoInicial, setTiempoInicial] = useState<number[]>([])// QUE?
  const [tiempoFinal, setTiempoFinal] = useState<number[]>([]) // QUE?
  const [aciertoCartas, setAciertoCartas] = useState<boolean[]>([])
  const [empezarContador, setEmpezarContador] = useState(true)
  const [modoEstudiar, setModoEstudiar] = useState(0)
  //modo 1, estudio de tarjetas de examen
  //modo 2, estudio de tarjetas de examen 3 dias previos
  //modo 3, simulacro de examen
  //modo 4, estudio de tarjetas sin examen
  //modo 5, repaso de tarjetas sin examen
  const [estNumStart, setEstNumStart] = useState(0)
  const [estNumFinal, setEstNumFinal] = useState(0)
  const [estNumAcierto, setEstNumAcierto] = useState(0)
  const [estTiempo, setEstTiempo] = useState(0)

  const [studyPlan, setStudyPlan] = useState<number[]>([])
  const [remainingTime, setRemainingTime] = useState(time?time * 60 * 1000:0)
  //const [remainingTime, setRemainingTime] = useState(6000)

  type StudyMode = 'study' | 'repaso' | 'examStudy' | 'examRepaso' | 'simulacro'
  const [studyMode, setStudyMode] = useState<StudyMode>('study')
  const [confirmarEliminarCarta, setConfirmarEliminarCarta] = useState<boolean>(false)
  const [testResponse, setTestResponse] = useState<boolean | null>(null)
  /*
  card: TestCard 
  onResponse: (correct: boolean) => void
  */
  const [editandoCarta, setEditandoCarta] = useState<boolean>(false)
  const [key, setKey] = useState<number>(0);

  const navigate = useNavigate();

  const ValueGoodCard= Math.floor(flashcardsEstudiar[flashcardIndex]?.I<1440?1:flashcardsEstudiar[flashcardIndex]?.I/1440)

  const isTokenExpired = () => {
    const token = localStorage.getItem('rachaToken');
    if (!token) {
      return true; // No token found, consider it expired
    }
  
    try {
      const tokenData = JSON.parse(atob(token));
      const currentTime = Date.now();
      return currentTime > tokenData.expiresAt;
    } catch (error) {
      console.error('Error decoding token:', error);
      return true; // If there's an error decoding the token, consider it expired
    }
  };

  useEffect(() => {
    if (modo == 3 && time && flashcardsEstudiar[0] != undefined && !loading) {
      const tiempoInicial = new Date().getTime();
      const intervalId = setInterval(() => {
        setRemainingTime(prevTime => {
          const tiempoRestante = prevTime - 1000;
          if (tiempoRestante > 0) {
            //console.log(tiempoRestante);
            return tiempoRestante;
          } else {
            const fechaActual = new Date().getTime();
            const elapsedTimeInSeconds = (fechaActual - tiempoInicial) / 1000;
            setEstTiempo(elapsedTimeInSeconds);
            if (cartas) {
              setEstNumFinal(cartas.length);
            }
            setFinPreguntas(true);
            clearInterval(intervalId);
            // llamar a guardar simulacro con estNumAcierto/flashcardsEstudiar.length y la fecha de hoy y el examen.date
            return 0;
          }
        });
      }, 1000);
  
      return () => clearInterval(intervalId);
    }
  }, [flashcardsEstudiar, loading]);

  useEffect(() => {
    const handleKeyDown = (e: { key: string }) => {
      if(!finPreguntas){
        if(mostrarRespuesta){
          if (e.key === 'ArrowLeft') {
            handleNextFlashcardBad()
          }
          if (e.key === 'ArrowRight') {
            handleNextFlashcardGood()
          }
        }else{
          if (e.key === ' ' || e.key === 'Spacebar' || e.key === 'Space' || e.key === 'ArrowUp' || e.key === 'ArrowDown' || e.key === 'Enter') {
            mostrarRespuestaDePregunta()
          }
        }
      }
    }
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [mostrarRespuesta, finPreguntas] )

  //MIERDA
  const mostrarRespuestaDePregunta = async() =>  {
    let lastFlashCardIndex=flashcardIndex
    setMostrarRespuesta(true)
    let fechaActual=new Date()
    tiempoFinal[lastFlashCardIndex]=(fechaActual.getTime())
  }

  useEffect(() => {
    const fetchFlashcards = async () => {
      if (cartas && modo) {
        try{
					const response = await fetch (
						`${import.meta.env.VITE_API_URL}/user/studyPlan`, 
						{
							method: 'GET',
							headers: { 'Content-Type': 'application/json', },
							credentials: 'include',
						}
					)
					if(!response.ok) {
            console.log(response)
						throw new Error('Error al obtener los datos del usuario')
					}
					const data = await response.json()
          setStudyPlan(data)
          setFlashcardEstudiarSinOrdenar(cartas)
          setModoEstudiar(modo)

				} catch(error) {
					console.log(error)
				}
      }
    }
		fetchFlashcards()
	}, [cartas, modo])

  useEffect(() => {
    const desordenar = async () => { 
      let FcMasAntigua: any[] = []
      for (let i = 0; i < flashcardsEstudiarSinOrdenar.length; i++) {
          if (flashcardsEstudiarSinOrdenar[i].dateLastUsed && flashcardsEstudiarSinOrdenar[i].dateLastUsed.length>0) {
              let FCI=flashcardsEstudiarSinOrdenar[i].I
              let date1 = new Date(flashcardsEstudiarSinOrdenar[i].dateLastUsed[flashcardsEstudiarSinOrdenar[i].dateLastUsed.length-1]);
              let date2 = new Date()
              let datedif=((date2.getTime() - date1.getTime())/(60000*60*24)-FCI/(60*24))
              FcMasAntigua[i]=datedif
          } else {
              FcMasAntigua[i]=0
          }
      } 
      for (let i = 0; i < flashcardsEstudiarSinOrdenar.length; i++) {
          for (let j = 0; j < flashcardsEstudiarSinOrdenar.length - 1; j++) { 
              if (FcMasAntigua[j] < FcMasAntigua[j + 1]) {
              let aux: number = FcMasAntigua[j];
              FcMasAntigua[j] = FcMasAntigua[j + 1];
              FcMasAntigua[j + 1] = aux;
              let aux2 = flashcardsEstudiarSinOrdenar[j];
              flashcardsEstudiarSinOrdenar[j] = flashcardsEstudiarSinOrdenar[j + 1];
              flashcardsEstudiarSinOrdenar[j + 1] = aux2;
              }
          }
      }
      let intervalo=0
      if(flashcardsEstudiarSinOrdenar.length>100){
          intervalo=Math.floor(flashcardsEstudiarSinOrdenar.length/10)
      } else if (flashcardsEstudiarSinOrdenar.length>50 && flashcardsEstudiarSinOrdenar.length<=100){
          intervalo=Math.floor(flashcardsEstudiarSinOrdenar.length/5)
      } else if (flashcardsEstudiarSinOrdenar.length>0 && flashcardsEstudiarSinOrdenar.length<=50){
          intervalo=Math.floor(flashcardsEstudiarSinOrdenar.length/3)
      }
      if(intervalo>0){
          for (let i = 0; i < flashcardsEstudiarSinOrdenar.length-intervalo; i=i+intervalo) {

              if(i+intervalo>flashcardsEstudiarSinOrdenar.length-intervalo){
                  let resto=flashcardsEstudiarSinOrdenar.length%intervalo
                  for (let j = i; j < i+intervalo+resto; j++) {
                      let aux1 = flashcardsEstudiarSinOrdenar[j];
                      let k = Math.floor(Math.random() * (intervalo+resto) + i);
                      flashcardsEstudiarSinOrdenar[j] = flashcardsEstudiarSinOrdenar[k];
                      flashcardsEstudiarSinOrdenar[k] = aux1;
                  }
              } else {
                  for (let j = i; j < i+intervalo; j++) {
                      let aux1 = flashcardsEstudiarSinOrdenar[j];
                      let k = Math.floor(Math.random() * (intervalo) + i);
                      flashcardsEstudiarSinOrdenar[j] = flashcardsEstudiarSinOrdenar[k];
                      flashcardsEstudiarSinOrdenar[k] = aux1;
                  }
              }
          }
      }
      setEstNumStart(flashcardsEstudiarSinOrdenar.length)
      let fechaActual=new Date()
      setEstTiempo(fechaActual.getTime())
      setFlashcardEstudiar(flashcardsEstudiarSinOrdenar)
      setLoading(false)
      setFinPreguntas(false)
    }
    desordenar()
    //setLoading(false)
  },[flashcardsEstudiarSinOrdenar])


  useEffect(() => {
    if (empezarContador === true) {
      let lastFlashCardIndex=flashcardIndex
      let fechaActual=new Date()
      tiempoInicial[lastFlashCardIndex]=(fechaActual.getTime())
      setEmpezarContador(false)
    }
  },[empezarContador])

  const volverARepasar = async() =>  {
    if(cartas){
      
      let Array: Card[] = []
      for(let i=0;i<estNumStart;i++){
        Array.push(flashcardsEstudiar[i])
      }
      setModoEstudiar(2)
      setMostrarRespuesta(false)
      setFlashcardIndex(0)
      setEstNumStart(Array.length)
      setEstNumFinal(Array.length)
      setEstNumAcierto(0)
      let fechaActual=new Date()
      setEstTiempo(fechaActual.getTime())
      setFlashcardEstudiarSinOrdenar(Array)
    }
  } 


  const handleNextFlashcardGood = async() => {
    setAciertoCartas([...aciertoCartas, true])
    let currentNumAciertos=estNumAcierto
    setEstNumAcierto(estNumAcierto+1)
   
    let lastFlashCardIndex=flashcardIndex
    let cartaAcertada = JSON.parse(JSON.stringify(flashcardsEstudiar[lastFlashCardIndex]));
      if (modoEstudiar==1 || modoEstudiar==2 || modoEstudiar==4){

        let arrayAciertos = cartaAcertada.aciertos || []
        if (arrayAciertos.length >= 15) {
          arrayAciertos = arrayAciertos.slice(-14)
        }
        arrayAciertos.push(true)

        let diferenciaTiempo=-1
        if (tiempoFinal[lastFlashCardIndex]!=undefined && tiempoInicial[lastFlashCardIndex]!=undefined){
          diferenciaTiempo=((tiempoFinal[lastFlashCardIndex] - tiempoInicial[lastFlashCardIndex])/(1000))
        }else{

        }

        let arrayTiempos = cartaAcertada.timePerAnswer || []
        if (arrayTiempos.length >= 15) {
          arrayTiempos = arrayTiempos.slice(-14)
        }
        arrayTiempos.push(diferenciaTiempo)

        let FCEF=cartaAcertada.EF
        let FCI=cartaAcertada.I
        let FCR=cartaAcertada.R
        if (FCR===0){
          FCI=1440 //antes era 0, ahora para que no repita 1440
        } else if (FCR===1){
          FCI=1440
        } else if(FCR>1){
          FCI=FCI*FCEF
        } 
        FCEF=FCEF+0.1
        FCR=FCR+1

        let arrayFechaUsado = cartaAcertada.dateLastUsed || []
        if (arrayFechaUsado.length >= 15) {
          arrayFechaUsado = arrayFechaUsado.slice(-14)
        }
        const currentDate = new Date()
        arrayFechaUsado.push(currentDate)
        if(modoEstudiar==1){















          let fechaPush: Date | null = null;
          
          const dateLastUsed = new Date();
          dateLastUsed.setMinutes(dateLastUsed.getMinutes() + FCI);
          const cardExamDate = dateLastUsed;  


          function normalizeToMidnightUTC(date: Date): Date {
            return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()));
          }
          

          const examDateObj = normalizeToMidnightUTC(new Date(cardExamDate));
          const currentDate = normalizeToMidnightUTC(new Date());
          const daysDifference = Math.floor((examDateObj.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24));

        
          if (daysDifference < 0 || daysDifference >= studyPlan.length) {
            // Si la fecha del examen no está dentro del rango de studyPlan, devolver la fecha original
            fechaPush=cardExamDate;
          }
          if(!fechaPush){
            if (studyPlan[daysDifference] == 0) {
              // Si el día del examen es un día libre, usar esa fecha
              fechaPush=cardExamDate;
            }
          }

          if(!fechaPush){
            // Buscar el día libre más cercano
            let nearestFreeDay = examDateObj;
            let foundFreeDay = false;
            const randomChoice = Math.random() < 0.5 ? -1 : 1; // 50% posibilidades de buscar por debajo o por encima
          
            for (let i = 1; i < studyPlan.length; i++) {
              const index = daysDifference + i * randomChoice;
              if (index > 0 && index < studyPlan.length && studyPlan[index] == 0) {
                nearestFreeDay.setDate(examDateObj.getDate() + i * randomChoice);
                fechaPush=nearestFreeDay;
                foundFreeDay = true;
                break;
              }
            }
          
            if (!foundFreeDay) {
              // Si no se encontró un día libre en la dirección elegida, buscar en la otra dirección
              for (let i = 1; i < studyPlan.length; i++) {
                const index = daysDifference - i * randomChoice;
                if (index > 0 && index < studyPlan.length && studyPlan[index] == 0) {
                  nearestFreeDay.setDate(examDateObj.getDate() - i * randomChoice);
                  fechaPush=nearestFreeDay;
                  foundFreeDay = true;
                  break;
                }
              }
            }
          }
          // Si no se encontró ningún día libre, poner el dia siguiente de la longitud de studyPLan
          if (!fechaPush) {
            let nearestFreeDay = currentDate;
            nearestFreeDay.setDate(currentDate.getDate() + studyPlan.length);
            fechaPush = nearestFreeDay;
          }

          const localOffset = fechaPush.getTimezoneOffset() * 60000; // Offset in milliseconds
          const localDate = new Date(fechaPush.getTime() - localOffset);
  
          const utcYear = localDate.getUTCFullYear();
          const utcMonth = String(localDate.getUTCMonth() + 1).padStart(2, '0');
          const utcDay = String(localDate.getUTCDate()).padStart(2, '0');
          const formattedUTCDate = `${utcYear}-${utcMonth}-${utcDay}`;
          if(cartaAcertada.exam_date.length>0){
            cartaAcertada.exam_bool.pop();
            cartaAcertada.exam_bool.push(true);
            cartaAcertada.exam_bool.push(false);
            cartaAcertada.exam_date.push(formattedUTCDate);
          } else{
            cartaAcertada.exam_date=[formattedUTCDate]
            cartaAcertada.exam_bool=[false]
          }




        }
        if(modoEstudiar==2){
          cartaAcertada.final_bool=true
        }

        cartaAcertada.I=FCI
        cartaAcertada.R=FCR
        cartaAcertada.EF=FCEF
        cartaAcertada.dateLastUsed=arrayFechaUsado
        cartaAcertada.timePerAnswer=arrayTiempos
        cartaAcertada.aciertos=arrayAciertos
        /*
        if(FCI==0){
          let FCList=flashcardsEstudiar
          FCList.push(flashcardsEstudiar[lastFlashCardIndex])
          let startIndex = Math.max(lastFlashCardIndex, estNumStart);
          let randomIndex = Math.floor(Math.random() * (FCList.length - startIndex)) + startIndex;
          let temp = FCList[FCList.length - 1];
          FCList[FCList.length - 1] = FCList[randomIndex];
          FCList[randomIndex] = temp;
          setFlashcardEstudiar(FCList)
        }
          */
      }
      if (flashcardIndex == flashcardsEstudiar.length - 1) {
        let tiempoInicial=estTiempo
        let fechaActual=new Date()
        setEstTiempo((fechaActual.getTime()-tiempoInicial)/1000)
        setEstNumFinal(flashcardsEstudiar.length)
        setFinPreguntas(true)
        //GUARDAR EN DB SI MODO ES 3 EL VALOR DE ACIERTOS Y FECHA DEL SIMULACRO
        //onClickReturn()
        guardarSimulacro(currentNumAciertos + 1)
      } else {
        setKey((prevKey) => prevKey + 1)
        setFlashcardIndex(flashcardIndex + 1)
        setMostrarRespuesta(false)
        setEmpezarContador(true)
      }

      if (isTokenExpired()) {

        const response = await fetch(
          `${import.meta.env.VITE_API_URL}/estadisticas/update_stats?racha_lastDate=${true}`,
          {
              method: 'PATCH',
              headers: {
                  'Content-Type': 'application/json',
              },
              credentials: 'include'
          }
      );
      if (!response.ok) {
          throw new Error('Error al actualizar las estadisticas');
      }
        const now = new Date();
        const expirationDate = new Date(now);
        expirationDate.setHours(23, 59, 59, 999); // Establecer la hora a las 23:59:59 del mismo día
      
        const expiresIn = expirationDate.getTime() - now.getTime();
        const tokenData = {
          data: 'yourData',
          expiresAt: Date.now() + expiresIn,
        };
        const token = btoa(JSON.stringify(tokenData));
        localStorage.setItem('rachaToken', token);
      }

    if (modoEstudiar==1 || modoEstudiar==2 || modoEstudiar==4){
      try {
        const response = await fetch(
          `${import.meta.env.VITE_API_URL}/card/study`, 
          {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json', },
            body: JSON.stringify(cartaAcertada),
          }
        )
        if (!response.ok){
          throw new Error('Error al actualizar los valores de la carta');
        }			
        



  



      } catch (error) {
        console.error('Error:', error);
      }
    }
  }


  const handleNextFlashcardBad = async() => {
    setAciertoCartas([...aciertoCartas, false])
    let lastFlashCardIndex=flashcardIndex
    //let cartaFallada = flashcardsEstudiar[lastFlashCardIndex]
    let cartaFallada = JSON.parse(JSON.stringify(flashcardsEstudiar[lastFlashCardIndex]));
    if (modoEstudiar==1 || modoEstudiar==2  || modoEstudiar==4 || modoEstudiar==5){
      

      let arrayAciertos = cartaFallada.aciertos || [];
      if (arrayAciertos.length >= 15) {
        arrayAciertos = arrayAciertos.slice(-14)
      }
      arrayAciertos.push(false)
      cartaFallada.aciertos = arrayAciertos;


      let diferenciaTiempo=-1
      if (tiempoFinal[lastFlashCardIndex]!=undefined && tiempoInicial[lastFlashCardIndex]!=undefined){
        diferenciaTiempo=((tiempoFinal[lastFlashCardIndex] - tiempoInicial[lastFlashCardIndex])/(1000))
      }else{

      }

      let arrayTiempos = cartaFallada.timePerAnswer || []
      if (arrayTiempos.length >= 15) {
        arrayTiempos = arrayTiempos.slice(-14)
      }
      arrayTiempos.push(diferenciaTiempo)
      cartaFallada.timePerAnswer = arrayTiempos

      cartaFallada.I=0
      cartaFallada.R=1
      cartaFallada.EF =cartaFallada.EF-0.5
      if(cartaFallada.EF<1.3){
        cartaFallada.EF=1.3
      }

      let dateLastUsed = cartaFallada.dateLastUsed || []
      if (dateLastUsed.length >= 15) {
        dateLastUsed = dateLastUsed.slice(-14)
      }
      const currentDate = new Date()
      dateLastUsed.push(currentDate)
      cartaFallada.dateLastUsed = dateLastUsed

      if(modoEstudiar==2){
        cartaFallada.final_bool=false
      }



      let FCList=flashcardsEstudiar
      FCList.push(cartaFallada)
      let startIndex = Math.max(lastFlashCardIndex, estNumStart);
      let randomIndex = Math.floor(Math.random() * (FCList.length - startIndex)) + startIndex;
      let temp = FCList[FCList.length - 1];
      FCList[FCList.length - 1] = FCList[randomIndex];
      FCList[randomIndex] = temp;
      setFlashcardEstudiar(FCList)
    }
    if (flashcardIndex === flashcardsEstudiar.length - 1) {
      let tiempoInicial=estTiempo
      let fechaActual=new Date()
      setEstTiempo((fechaActual.getTime()-tiempoInicial)/1000)
      setEstNumFinal(flashcardsEstudiar.length)
      setFinPreguntas(true)
      //onClickReturn()
      guardarSimulacro(estNumAcierto)
    } else {
      setKey((prevKey) => prevKey + 1)
      setFlashcardIndex(flashcardIndex + 1)
      setMostrarRespuesta(false)
      setEmpezarContador(true)
    }
    if (modoEstudiar==1 || modoEstudiar==2 || modoEstudiar==4){
      try {
        const response = await fetch(
          `${import.meta.env.VITE_API_URL}/card/study`, 
          {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json', },
            body: JSON.stringify(cartaFallada),
          }
        )
        if (!response.ok){
          throw new Error('Error al conseguir cartas de examen');
        }			
      } catch (error) {
        console.error('Error:', error);
      }
    }
  }


  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60000).toString().padStart(2, '0');
    const seconds = Math.floor((time % 60000) / 1000).toString().padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  const percentage = (flashcardIndex / flashcardsEstudiar.length) * 100;


  const handleAnteriorConcepto = async() => {
    if (flashcardIndex > 0) { 
      setFlashcardIndex(flashcardIndex - 1)
      setMostrarRespuesta(false)
      setEmpezarContador(true)
      if(aciertoCartas[flashcardIndex-1]==true){
        setEstNumAcierto(estNumAcierto-1)
      } else if(modoEstudiar==1 || modoEstudiar==2 || modoEstudiar==4 || modoEstudiar==5){
        //borrar la ultima instancia que haya en flashcardsEstudiar que tenga el id de la carta anterior
        let FCList=flashcardsEstudiar
        let i=FCList.length-1
        while(i>=0){
          if(FCList[i].id==flashcardsEstudiar[flashcardIndex-1].id){
            FCList.splice(i,1)
            break
          }
          i--
        }
        setFlashcardEstudiar(FCList)
      }
      setAciertoCartas(aciertoCartas.slice(0, aciertoCartas.length-1))
      //hacer que los valores de la carta anterior se deshagan en funcion de si se ha puesto
      //true o false en la ultima.reponer contador de tiempo
    }
  }


  const editarTarjeta = async() => {
    setEditandoCarta(true)
  }

  const eliminarTarjeta = async() => {
    setConfirmarEliminarCarta(true)
  }

  const guardarSimulacro = async(numAciertos:number) => {
    if (modoEstudiar == 3) {
      try {
        const currentDate = new Date();
        const localOffset = currentDate.getTimezoneOffset() * 60000;
        const localDate = new Date(currentDate.getTime() - localOffset);
        const utcYear: number = localDate.getUTCFullYear();
        const utcMonth: string = String(localDate.getUTCMonth() + 1).padStart(2, '0');
        const utcDay: string = String(localDate.getUTCDate()).padStart(2, '0');
        const formattedUTCDate = `${utcYear}-${utcMonth}-${utcDay}`;

        const response = await fetch(  
          `${import.meta.env.VITE_API_URL}/simulacro/create`,
          {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({exam_id: examen?.id,
              fecha: formattedUTCDate,
              aciertos: numAciertos,
              final: flashcardsEstudiar.length}),
              credentials: 'include'
          })
        if (!response.ok){
          console.log(response)
          throw new Error('Error al guardar el simulacro');
        }			
      } catch (error) {
        console.log(error)
        console.error('Error:', error);
      }
    }
  }

  const handleDeleteCard = async() => {
    //const updatedFlashcards = flashcardsEstudiar.filter((card, index) => index !== flashcardIndex);
    //setFlashcardEstudiar(updatedFlashcards);


    if (flashcardIndex >= 0) { 
      let currentNumAciertos=estNumAcierto
      let numEliminadas=0
      //setFlashcardIndex(flashcardIndex)
      setMostrarRespuesta(false)
      setEmpezarContador(true)
      let id=flashcardsEstudiar[flashcardIndex].id
      let updatedFlashcards=[...flashcardsEstudiar]
      let i=updatedFlashcards.length-1
      while(i>=0){
        if(updatedFlashcards[i].id==id){
          numEliminadas++
          updatedFlashcards.splice(i,1)
          if(aciertoCartas[i]==true){
            setEstNumAcierto(estNumAcierto-1)
            currentNumAciertos--
          }
          aciertoCartas.splice(i,1)
        }
        i--
      }
      let index=flashcardIndex
      if(numEliminadas>1){
        index=flashcardIndex-numEliminadas+1
      }
      setEstNumStart(estNumStart-1)
      setEstNumFinal(estNumFinal-numEliminadas)
      if(index>=updatedFlashcards.length){
        let tiempoInicial=estTiempo
        let fechaActual=new Date()
        setEstTiempo((fechaActual.getTime()-tiempoInicial)/1000)
        setEstNumFinal(updatedFlashcards.length)
        setFinPreguntas(true)
        //onClickReturn()
        guardarSimulacro(currentNumAciertos)
      }else{
        if(numEliminadas>1){
          setFlashcardIndex(flashcardIndex-numEliminadas+1)
        }
        setFlashcardEstudiar(updatedFlashcards)
      }
      setConfirmarEliminarCarta(false)
    }
  }

  const handleEditCard = async (editCard: CardInit) => {
    let id = flashcardsEstudiar[flashcardIndex].id
    for(let i=0;i<flashcardsEstudiar.length;i++){
      if(flashcardsEstudiar[i].id==id){
        flashcardsEstudiar[i]=editCard as Card
      }
    }
  }

	return (<>
  {!editandoCarta?
    flashcardsEstudiar[0] != undefined && !loading ?
      <>
        <div className='Exam h-full flex flex-col justify-between'>
          <div className='flex flex-col sm:flex-row justify-between items-start w-full mt-5 pt-20'>
            <GoBackArrow onClick={()=>navigate(-1)} />
            {!finPreguntas ? (
              <>
                <div className='w-full flex flex-col justify-start items-center mb-3'>
                  <div style={{ display: 'flex', alignItems: 'center', width: '90%', marginTop: '10px' }}>
                    <div style={{ flex: 1, backgroundColor: '#e0e0df', borderRadius: '5px', overflow: 'hidden' }}>
                      <div
                        style={{
                          width: `${percentage}%`,
                          height: '10px',
                          backgroundColor: 'var(--color-secondary)',
                          transition: 'width 0.5s'
                      }}/>
                    </div>
                    <div style={{ marginLeft: '15px', fontWeight: '500', fontSize: '1em' }}>
                      {flashcardIndex}/{flashcardsEstudiar.length}
                    </div>
                  </div>
                  {time && time > 0 ?
                    <div style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '40%',
                      marginTop: '5px',
                      fontSize: '1.8rem',
                      fontWeight: '500',
                      color: 'var(--color-text-light)',
                    }}>
                      {formatTime(remainingTime)}
                    </div>
                    : null}
                </div>
                <Button
                  className={`hidden sm:flex items-center justify-center mr-[30px] w-[300px] bg-gray-300 !text-black hover:bg-medium-color border-none rounded-[225px] py-[10px] gap-[10px]`}
                  disabled={flashcardIndex == 0}
                  onClick={() => handleAnteriorConcepto()}
                >
                  <Icon iconName='undo' isClickable={flashcardIndex > 0}/>
                  <h4>Anterior concepto</h4>
                </Button>
              </>
            ) : null}
          </div>
          {finPreguntas ?
            <div className='card-end-body h-[92vh] md:h-[70vh]'>
              <>
                <div style={{ height: '4vh' }} />
                <div className='text-center max-w-full mx-auto'>
                  {/* ESTAN MAL PUESTOS */}
                  {modoEstudiar == 1 || modoEstudiar == 4 ?
                    <>
                      <div className='text-secondary-color font-bold text-3xl md:text-6xl pb-2'>¡ENHORABUENA!</div>
                      <h3>Has terminado el estudio de este tema por hoy</h3>
                    </>
                    : (modoEstudiar == 2 || modoEstudiar == 5 ?
                      <h2 className='text-secondary-color'>
                        Has terminado de repasar el tema
                      </h2>
                      :
                      <h2 className='text-secondary-color'>
                        Ha terminado el simulacro
                      </h2>
                    )}
                  <div style={{ height: '2vh' }} />
                  {modoEstudiar != 3 ? (
                    <>
                      {estNumStart == 1 ?
                        <h4>{estNumStart} Tarjeta estudiada</h4>
                        :
                        <h4>{estNumStart} Tarjetas estudiadas</h4>}
                      <h4>Conocimiento {Math.round(estNumAcierto / estNumFinal * 100)}%</h4>
                      <h4>
                        Tiempo {(Math.floor(estTiempo / 60)).toString().padStart(2, '0')}:
                        {(Math.floor(estTiempo % 60)).toString().padStart(2, '0')} minutos
                      </h4>
                    </>
                  ) :
                    <>
                      <h3>Nota {Math.round(estNumAcierto / estNumFinal * 100) / 10}/10</h3>
                      <h3>{estNumAcierto}/{estNumFinal} Conceptos memorizados</h3>
                      <h3>
                        Tiempo {(Math.floor(estTiempo / 60)).toString().padStart(2, '0')}:
                        {(Math.floor(estTiempo % 60)).toString().padStart(2, '0')} minutos
                      </h3>
                    </>}
                </div>
                <div style={{ height: '5vh' }} />
                <div>
                  <Button className='bien' onClick={()=>navigate(-1)}>
                    {modoEstudiar == 1 || modoEstudiar == 2 || modoEstudiar == 3 ? 'Volver a examenes' : 'Volver a biblioteca'}
                  </Button>
                  {/*
            <button className='bien' onClick={volverARepasar}>
              Seguir repasando
            </button>
            */}
                </div>
              </>
            </div>
            : <div className='card-body'>{/* MOVER LOS BODIES A SUBCOMPONENTES */}
              {flashcardsEstudiar[flashcardIndex].type === 'flashcard' ? (
                <FlashCardStudy
                  card={flashcardsEstudiar[flashcardIndex] as FlashCard}
                  revealed={mostrarRespuesta} />
              )
                : flashcardsEstudiar[flashcardIndex].type === 'ocultar' ? (
                  <OcultarCardStudy
                    card={flashcardsEstudiar[flashcardIndex] as OcultarCard}
                    revealed={mostrarRespuesta} />
                )
                  : flashcardsEstudiar[flashcardIndex].type === 'test' ? (
                    <TestCardStudy
                      key={key}
                      card={flashcardsEstudiar[flashcardIndex] as TestCard}
                      onResponse={(correct) => {setTestResponse(correct);mostrarRespuestaDePregunta()}} />
                  )
                    : <h2>Ha ocurrido un error, contacte con nuestro soporte en bilern.team@gmail.com</h2>}
            </div>}
          {!finPreguntas && (flashcardsEstudiar[flashcardIndex].type !== 'test' || (flashcardsEstudiar[flashcardIndex].type === 'test' && mostrarRespuesta))?
            <div className='card-footer h-24 bg-white flex justify-center items-center'>
              {!mostrarRespuesta?
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
                  <Button className='min-w-80 md:w-48 !h-12 hover:bg-medium-color !text-sm !bg-gray-100 hover:!bg-gray-200 hover:!text-black' onClick={mostrarRespuestaDePregunta}>
                    Mostrar Respuesta
                  </Button>
                </div>

                :
                <div className='w-full px-4 flex flex-row justify-center sm:justify-between items-center'>
                  <button className='invisible hidden sm:block sm:w-40 md:w-52 !h-12' style={{color:'black'}} onClick={editarTarjeta}>
                    Editar Tarjeta
                  </button>
                  {flashcardsEstudiar[flashcardIndex].type !== 'test' ?
                  <div className='flex flex-row gap-4'>
                    <Button 
                      className='mal group gap-2 w-30 md:w-36 !h-12 !text-base !rounded-xl flex justify-between !px-5 items-center !bg-gray-100 hover:!bg-red-100'
                      onClick={handleNextFlashcardBad}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') event.preventDefault()
                      }}
                    >
                        Mal
                      <div className="text-xs group-hover:text-red-500 text-gray-500 flex items-center justify-center gap-1">
                      <BiTimer size={18} ></BiTimer>
                        {'< 1m'}
                      </div>
                    </Button>
                    <Button
                      className='bien group gap-2 w-30 md:w-36 !h-12 !text-base !rounded-xl flex justify-between !px-5 items-center !bg-gray-100 hover:!bg-blue-100'
                      onClick={handleNextFlashcardGood}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') event.preventDefault()
                      }}
                    >
                      Bien 
                      <div className="text-xs text-gray-500 group-hover:text-blue-500 flex items-center justify-center gap-1">
                        <BiTimer size={18} ></BiTimer>{ValueGoodCard}
                        {ValueGoodCard==1?' día':' días'}
                      </div>
                    </Button>
                  </div>
                  :
                  <Button 
                    className='w-64 sm:w-32 md:w-48 !h-12 hover:bg-medium-color !text-black'
                    onClick={() => {testResponse ? handleNextFlashcardGood() : handleNextFlashcardBad()}}
                  >
                    Continuar
                  </Button>}
                  <Button className='hidden !font-medium hover:bg-medium-color sm:block sm:w-40 md:w-52 !h-12 !bg-gray-100 !text-sm ' style={{color:'black'}} onClick={eliminarTarjeta}>
                    Eliminar Tarjeta
                  </Button>
                </div>}
            </div>
          : 
          null}
        </div>
        <Popup 
          open={confirmarEliminarCarta} 
          children={<ConfirmDeleteCard 
          cardId={flashcardsEstudiar[flashcardIndex].id} 
          cardType={flashcardsEstudiar[flashcardIndex].type} 
          onReturn={()=>setConfirmarEliminarCarta(false)}
          onReturnDelete={()=>{handleDeleteCard()}}/>} 
          onClose={()=>setConfirmarEliminarCarta(false)} width='700px'
        />
      </>
    : 
      <LoadingTest />
    :
    (<EditorsProvider><CardEditor mazo={null} onClickReturn={()=>setEditandoCarta(false) } folder={null} 
          cartaEditar={flashcardsEstudiar[flashcardIndex] as Card} returnToStudy={(editCard: CardInit)=>{handleEditCard(editCard)}}/></EditorsProvider>)}
    </>)
}