import DashboardElementContainer from "@/pages/App/pages/Dashboard/components/DashboardElementContainer";
import {Link} from "react-router-dom";
import {BiCheck, BiFile, BiInfoCircle} from "react-icons/bi";
import {Avatar} from "@ark-ui/react";
import {useUser} from "@/api/user/useUser";
import {usePricingPlan} from "@/api/user/usePricingPlan";
import {calculatePages} from "@/utils/tokens";
import {BsStars} from "react-icons/bs";
import { Popover } from '@ark-ui/react/popover'

function UserProfileCard() {
    const {data, status: userStatus} = useUser();
    const {data: plan, status: planStatus} = usePricingPlan();

    return (
        <DashboardElementContainer className="h-auto" title="Tu cuenta" status={[userStatus, planStatus]}>
            <div className="mt-4 flex items-center gap-2">
                <Avatar.Root className="w-[65px] h-[65px] bg-brand_primary-700 flex items-center justify-center rounded-full overflow-hidden">
                    <Avatar.Fallback className=" text-white  font-semibold">
                        {(data?.nombre.slice(0, 1).toUpperCase() ?? "") + (data?.apellidos.slice(0, 1).toUpperCase() ?? "")}
                    </Avatar.Fallback>
                </Avatar.Root>
                <div className="gap-0 ml-2">
                    <div className="font-semibold m-0 p-0 text-gray-800 text-base !text-left">{data?.nombre} {data?.apellidos}</div>
                    <div className="text-gray-500 text-xs m-0 p-0 !text-left">@{data?.usuario}</div>
                </div>
            </div>
            <div className="p-3 bg-brand_secondary-500 rounded-xl flex items-center gap-3 mt-3">
                    <div className="flex-shrink-0 h-[50px] w-[50px] bg-brand_secondary-800 flex justify-center items-center rounded-xl">
                        <BsStars size={20} className="text-white"/>
                    </div>
                <p className="text-white font-semibold">
                        {data ? <>{
                                data.tokens == 0 ? '0' :
                                    data.tokens < 800 ? '1' :
                                        '+' + calculatePages(data.tokens)
                            }</>
                            : '...'}
                        {data && data.tokens > 0 && data.tokens < 800 ?
                            ' página restante para importar con IA' :
                            ' páginas restantes para importar con IA'
                        }
                    </p>
                <Popover.Root>
                    <Popover.Trigger asChild>
                        <BiInfoCircle className="text-white text-lg p-1 rounded-full hover:bg-brand_secondary-800 cursor-pointer flex-shrink-0"></BiInfoCircle>
                    </Popover.Trigger>
                    <Popover.Positioner>
                        <Popover.Content className=" p-4 bg-brand_secondary-800 rounded-xl text-white max-w-[300px]">
                            <Popover.Title >
                                <h4 className="font-semibold">¿Qué significa esto?</h4>
                            </Popover.Title>
                            <Popover.Description className="mt-3 text-sm">
                                Las páginas restantes indican el total de caras llenas de texto que puedes importar. Si tus documentos contienen menos texto, se descontará una cantidad proporcional de páginas.                           
                            </Popover.Description>
                        </Popover.Content>
                    </Popover.Positioner>
                </Popover.Root>
            </div>
            <div className="p-4 py-6 bg-brand_primary-500  mt-3 rounded-xl flex flex-col justify-between">
                <div className="mb-3">
                    <div className="flex justify-between items-center">
                        <div>
                            <span className="font-bold text-base text-white">{plan?.name ?? ''}</span>
                        </div>
                        <div className="font-bold text-white">
                            {(plan?.price ?? 0) / 100}€/mes
                        </div>
                    </div>
                    <div className="grid mt-4 gap-2">
                        {plan?.features.map((feature, i) => (
                            <div key={i} className="flex text-sm items-center gap-2">
                                <BiCheck size={20} className="text-white" />
                                <p className="text-white text-opacity-60">
                                    {feature}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
                <Link to={"/pricing"} className="w-fit font-semibold bg-brand_secondary-500 hover:!bg-brand_secondary-800 rounded-full p-2 text-sm px-3 text-brand_primary-500  box-border">
                    Cambiar plan
                </Link>
            </div>
        </DashboardElementContainer>
    );
}

export default UserProfileCard;