import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Spinner from '@/components/ui/Spinner'
import { useAuth } from '@/context/auth/useAuth'
import { useUpdateFolders } from '@/context/UpdateFoldersContext'

/**
 * Componente correspondiente a la página 'bilern.com/codigos/:code'
 * Esta página esta diseñada para ser utilizada como redirección para enlaces y QRs
 * El enlace a está pagina permite importar un nuevo folder para un usuario autenticado,
 * para lo cual sera necesario propocionar en la URL el código de un folder valido 
 * Si el usuario no esta autenticado se redireccionara a '/login' omitiendo esta página
 * @param code parametro de 'React Router' que contiene el código del folder a importar
 * @code {replace = true} permite borrar esta página del historial de navegación
 */
export default function ImportFromQr() {

  const auth = useAuth()
  const isAuth = auth?.isAuth
  const loading = auth?.loading
  const navigate = useNavigate()
  const { code } = useParams()
  const { setUpdateFolders } = useUpdateFolders()

  const importar = async(code: String) => {
    try {
      const response = await fetch(
        `${import.meta.env.VITE_API_URL}/import/code`, 
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', },
          body: JSON.stringify({code:code}),
          credentials: 'include',
        }
      )
      if (!response.ok){
        throw new Error('Error al conseguir cartas de examen')
      }			    
      let data = await response.json()
    } catch (error) {
      console.error('Error:', error)
    }
    setUpdateFolders(true)
    navigate('/dashboard')
	}

  useEffect(() => {
    if(!loading){
      if (!isAuth) {
        if (code) 
          sessionStorage.setItem('qrCodeImport', code)
        return navigate('/login', { replace: true })
      } else if (code) {
        importar(code)
      } else
        navigate('/dashboard')
    }
  }, [isAuth, navigate, loading])

  return (
    <div className='loadingTest'>
      <h2>Te estamos preparando un regalo!</h2>
      <Spinner />
    </div>
  )

}